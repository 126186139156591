import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import {
  mergePrismicPreviewData,
  usePrismicPreview,
} from "gatsby-source-prismic"
import { usePreview } from "../hooks/usePreview"
import { linkResolver } from "../utilities"
import { getI18n } from "react-i18next"

const Preview = () => {
  const previewContext = usePreview(),
    setContext = previewContext.setContext,
    preview = usePrismicPreview({
      repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY as string,
      linkResolver: linkResolver,
    }),
    newContext = {
      ...previewContext,
      exists: true,
      mergePrismicPreviewData,
      ...preview,
    },
    [navigated, setNavigated] = useState(false),
    { pathname } = useLocation(),
    { path, previewData } = preview,
    previewLang = path?.substring(1, 6),
    i18n = getI18n(),
    previewLangMismatch =
      previewLang &&
      i18n.options.supportedLngs &&
      i18n.options.supportedLngs.indexOf(previewLang) > -1 &&
      previewLang !== i18n.language

  // Temporarily change to document locale during previews
  useEffect(() => {
    if (!previewLang || !previewLangMismatch) return
    // Tell i18n handler not to store this language change
    window.__edpfm = { ignoreChangeLanguage: true }
    i18n.changeLanguage(previewLang)
  }, [previewLang, previewLangMismatch, i18n])

  // Update context
  useEffect(() => {
    if (setContext && previewContext.previewData !== previewData)
      setContext(newContext)
  }, [setContext, newContext, previewContext, previewData])

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If we've already sent someone on their way, don't keep messing with them
    if (navigated) return

    if (path) {
      navigate(path)
      setNavigated(true)
    }
  }, [navigated, setNavigated, path, pathname])

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-white">
      <h1>Loading</h1>
    </div>
  )
}

export default Preview
export { Preview }
